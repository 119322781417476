.knm-products-slider {

  margin: 0 -$grid-gutter-width/2;
  overflow: hidden;
  max-width: 100vw;
  z-index: 2;

  .img-wrapper {
    position: relative;

    img {
      position: relative;
      z-index: 2;
    }

    // shadow

  }


  .product-item {
    position: relative;
    padding-top: 1rem;
  }


  @include media-breakpoint-up(lg) {
    display: flex;
    margin: 0;
    position: relative;
    align-items: flex-end;
    overflow: visible;
    padding-bottom: 0;

    &:hover {
      .product-item {
        opacity: .4;
      }
    }

    &:before {
      display: block;
      position: absolute;
      content: "";
      transition: all 1s ease;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($white, 0);
      z-index: 998;
      pointer-events: none;
      transition: all 1s ease;

    }

    .product-item {
      transition: opacity 1.5s ease;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 5rem;

      &:hover {
        opacity: 1
      }
    }
  }


  .product-item > a {
    display: block;
  }


  // popover
  .additional-info {
    opacity: 0;
    padding: map_get($spacers, 3);
    box-shadow: 0 0 3rem 1rem rgba($black, .15);
    position: absolute;
    transform: translateY(110%);
    transition: opacity .75s ease, transform .75s ease;
    bottom: 0;
    width: 20rem;
    background-color: $white;
    @include caret(up);
    z-index: $zindex-select-dropdown;


    @include media-breakpoint-down(md) {
      position: relative;
      transform: translateY(0);
      margin-left: -3rem;
      width: calc(100% + 6rem);
      left: auto;
      margin-top: 2rem;
    }


    // popover triangle
    &:after {
      content: "";
      position: absolute;
      top: -10px;
      left: 50%;
      transform: translateX(-50%);
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 12px 10px 12px;
      border-color: transparent transparent $white transparent;
    }


    .productbox-price {
      font-size: $font-size-base;
      color: $text-muted;
    }


  }

  .knm-img {
    justify-content: center;
    overflow: visible;

    img {
      max-height: 25rem;
      object-fit: contain;
      overflow: visible;

      @include media-breakpoint-down(sm) {
        max-width: 85vw;
      }
    }

  }


  .product-item:hover {
    .additional-info {
      opacity: 1;
    }
  }

  @include media-breakpoint-down(lg) {

    .slick-list {
      overflow: visible;
    }

    .slick-slide {
      //max-width: 40vw;
      opacity: .5;

      &.slick-center {
        opacity: 1;

        .additional-info {
          opacity: 1 !important;
        }
      }
    }

    .slick-dots {
      position: absolute;
      left: 0;
    }
  }
}
