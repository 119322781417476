.productdetails-reviews-custom {
  max-width: map_get($container-max-widths, 'lg');
  margin: 0 auto;

  .subheadline {
    color: $black;
  }

  #reviews-overview {
    justify-content: center;

    .btn-outline-primary {
      @extend .btn-black;
    }

    .col-lg-8,
    .col-lg-4 {
      @include media-breakpoint-up(md) {
        @include make-col(6)
      }

      @include media-breakpoint-down(sm) {
        text-align: center;

        .dropdown {
          display: flex;
          justify-content: center;
        }
      }
    }

    .no-reviews {
      .col-lg-8 {
        @include make-col(12);
      }

      form.label-slide {
        text-align: center !important;
      }
    }

    .card {
      border-width: 0;
    }

    form.label-slide {
      @include media-breakpoint-up(md) {
        text-align: right;
      }
    }
  }
}

.rating-wrapper {
  margin-bottom: $spacer5;
}

.customer-review {

  .customer-review--rating {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacer3;

    > span {
      display: inline-block;
      margin-right: $spacer1;

      &:before {
        content: fa-content($fa-var-star);
        font-family: 'Font Awesome 5 Free';
        color: $black;
        font-size: 12px;
      }
    }

    @for $i from 1 through 5 {
      &[data-rating="#{$i}"] {
        > div:nth-child(n+#{($i + 1)}):before {
          color: $gray-light;
        }
      }
    }


  }


}

.review.card {
  transition: $transition-base;
  margin-bottom: 0 !important;
  padding-top: 0 ;
  padding-bottom:0 ;

  .blockquote-footer {
    font-style: italic;

    &:before {
      display: none;
    }
  }

  &.not-visible {
    overflow: hidden;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

}

.rating {
  color: $black;

  .far {
    color: $gray-light;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
}

