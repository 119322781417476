body[data-page="1"] {

  #content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.productbox-quick-actions,
.product-actions {
  //display: none !important;
}

