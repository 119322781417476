
.breadcrumb-wrapper {
  margin-bottom: 0;
}

.breadcrumb {
  @include font-size($font-size-base * (13 / $font-size-base));

  a {
    text-decoration: none;
  }
}

.breadcrumb-item {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
  }

  &.active {
    font-weight: $font-weight-bold;
  }

  &.first {
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

.breadcrumb-arrow {
  display: flex;
  @include media-breakpoint-up(md) {
    display: none;
  }

  &::before {
    @include fas('f104');
    margin-right: 6px;
  }
}

.breadcrumb-back {
  padding-right: map-get($spacers, 3);
  margin-right: map-get($spacers, 3);
  border-right: $border-width solid $gray-medium;
}

.navigation-arrows {
  display: none;
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }

}

.is-item-list {
  .breadcrumb-container {
    padding-left: 0;
    padding-right: 0;
  }
}


.breadcrumb-wrapper {
  .navigation-arrows {
    .btn {
      border-radius: 0;
      background-color: rgba($black, .2) !important;
      border-width: 0;

      &:hover {
        background-color: rgba($black, 1) !important;
      }
    }
  }

}
