.dropdown-menu {
 min-width: initial;
}

.dropdown-body,
.dropdown-footer { padding: $dropdown-item-padding-x;
	&:first-child { border-top-left-radius: $dropdown-border-radius; border-top-right-radius: $dropdown-border-radius; }
	&:last-child { border-bottom-left-radius: $dropdown-border-radius; border-bottom-right-radius: $dropdown-border-radius; }
}

.dropdown-item {
	background-color: $dropdown-link-bg-color;
	text-decoration: none;
}
.dropdown-divider {
	margin: 0;
}

.dropdown-toggle {
	&::after {
		@include fas('f078');
		margin-left: map-get($spacers, 2);
		vertical-align: middle;
		display: inline-block;
		line-height: 1;
		transition: none;
		border-top: 0;
		font-size: .75em;
	}
	&[aria-expanded='true']::after {
		transform: rotate(180deg);
	}

	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: nowrap;
	&.no-caret { display: block; }
	&.no-caret::after { display: none; }

	&:focus {
		outline: none;
	}
}

.dropdown-header {
	padding: map-get($spacers, 2) $dropdown-item-padding-x;
}

.dropdown-full-width {
	.dropdown-menu {
		width: 100%;
	}
}
.dropdown-toggle, [data-toggle=collapse]:not(.navbar-toggler) {
	white-space: initial;
}
