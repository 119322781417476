.newsletter-email-wrapper {
  > div {
    border-bottom: 1px solid $white;
  }
}

.modal.show.knm-modal.newsletter-exit-modal {
  .modal-header .close {
    //position: relative;
    background-color: transparent;
    transform: none;

    &:hover {
      color: $body-color;
    }
  }

  .modal-body {
    height: auto !important;
  }

  .modal-content .modal-body-inner {
    padding: 0;

    .col-img {

    }

    .col-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      > form {
        padding: $spacer3 $spacer4;
      }
    }

    .popup-legaltext {
      font-size: $font-size-sm;
      padding: $spacer2;
      color: $text-muted;
      text-align: center;
    }
  }

}


.newsletter-footer {
  background-color: $black;
  padding-top: $spacer5;
  padding-bottom: $spacer5;
  margin-top: $spacer5;

  .newsletter-footer-heading {
    color: $white;
  }


  button[type="submit"] {
    font-family: $headings-font-family;
    background: none;
    border: 0;
    font-size: $h3-font-size;

  }

  .form-control {
    background: none;
    border: 0;
    color: $white;

    &::placeholder {
      color: $white;
    }
  }
}

.newsletterform-sendinblue-input-wrapper {
  border-bottom: 2px solid $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    width: 100%;
  }

  .newsletter-optin-success {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  .alert {
    pointer-events: none;
    text-align: center;
  }

  .input-group {
    width: 100%;
  }


  &.success {
    .input-group {
      opacity: 0
    }

    .newsletter-optin-success {
      opacity: 1;
    }

  }
}

