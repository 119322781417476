
.#{$prefix}-modal { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: $zindex-modal; background-color: transparentize(darken($shaftblue-dark, 15%), .1); overflow-x: hidden; overflow-y: auto; transition: all .2s; padding: 1rem;
	&:not(.show) { opacity: 0; visibility: hidden; }
	&:not(.active) { display: none; }
}

.#{$prefix}-modal-content { margin: 2rem auto 0; max-width: 40rem; padding: 1rem; background: $white; border-radius: $border-radius; @include shadow;
	@include media-breakpoint-up(md) { padding: 2rem; }
}

.#{$prefix}-modal-close { border: 0; background: transparent; color: $gray-dark-consent; position: absolute; right: 0; top: 0; outline: 0; cursor: pointer; padding: 1rem; transition: all .2s;
	svg { width: 1.5rem; height: 1.5rem; }

	&:hover { color: $shaftblue-dark; }
}

.#{$prefix}-modal-icon { width: rem(65px); height: rem(65px); border-radius: 50%; background-color: inherit; color: $shaftblue; padding: 1rem; margin: -1rem auto -2rem; transform: translateY(-50%);
	@include media-breakpoint-up(md) { margin-top: -2rem; }
}
