.btn {


  &.btn-wide {
    min-width: 15rem;

    @include media-breakpoint-down(sm) {
      min-width: 10rem;
    }
  }

  &.btn-primary {
    color: $white;
  }


  &.btn-outline-black {
    color: $black;
    border-color: $black;

    @include hover {
      color: $white;
      background-color: $black;
    }
  }

  &.btn-outline-white {
    color: $white;
    border-color: $white;

    @include hover {
      color: $black;
      background-color: $white;
    }
  }


  &.btn-outline-primary {
    color: $black;

    @include hover {
      color: $white;
    }
  }

  &.btn-border-bottom {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    color: $white;

    &:after {
      content: "";
      height: 0;
      display: block;
      position: absolute;
      bottom: $spacer1;
      left: 0;
      width: 100%;
      border-bottom: $btn-border-width solid $white;

    }
  }

  &.btn-black {
    @include button-variant($black, $black);

    @include hover {
      background-color: #444;
      border-color: #444;
    }
  }

  &.btn-white {
    @include button-variant($white, $white);

    @include hover {
      background-color: $primary;
      color:$white;
    }
  }
}

.opc-Button {

  &.btn-black {
    .btn {
      @extend .btn-black;
    }
  }

  &.btn-wide {
    .btn {
      @extend .btn-wide;
    }
  }
}

.bootstrap-select {
  > .dropdown-toggle {
    border-color: $border-color;
    border-width: $input-border-width;

    @include hover {
      border-color: $border-color;

    }

    &:focus {
      border-color: $primary;
      outline: 0 !important;
    }
  }
}

