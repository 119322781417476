body[data-page="18"] {


  #main-wrapper > .knm-slider-wrapper {
    .title {
      &,& * {
        font-family: $headings-font-family;
      }
    }
  }

  .knm-hero-area-fp {
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - 80px) !important;

    }
  }

  @include media-breakpoint-up(lg) {
    .carousel:not(.slick-initialized).slick-type-product .product-wrapper {
      width: calc(100% / 3);
      min-width: calc(100% / 3);
    }

  }

  .frontpage-parallax {
    position: relative;
    @include blackOverlay;

    > div {
      position: relative;
      z-index: 3;


    }

    img {
      opacity: .7;


    }
  }


  #main-wrapper > div > div > .slider-wrapper {
    padding: 0 !important;
  }

  #content {
    padding-top: 0;
    padding-bottom: 0;
  }

  .slick-type-product {

  }

}


.frontpage-scroll-video {

  > div > .row {

    .col {
      &[data-area-id="col-0"],
      &[data-area-id="col-2"] {
        display: flex;
        align-items: center;

        > div {
          //position: sticky ;
          width: 100%;

        }

      }


      @include media-breakpoint-down(md) {
        &[data-area-id="col-0"] {
          order: 2;

          ul li:last-child {
            border-bottom: 1px solid $gray-light;
          }

        }

        &[data-area-id="col-1"] {
          order: 1;
          margin-bottom: $spacer5;
        }

        &[data-area-id="col-2"] {
          order: 3;
        }
      }

      ul {
        @include chekbox-list;

        li {
          text-align: center;
          display: flex;
          //justify-content: center;
          flex-direction: column;
          align-items: center;
          padding-top: $spacer4;
          padding-bottom: $spacer4;

          &:before {
            //position: relative;
            //margin-right: .5rem;
            //color: $gray-300;
            //content: fa-content($fa-var-angle-right);
            //margin-top: -.2rem;
            display: none;

          }

        }
      }
    }

    .frontpage-scroll-video--video {
      display: block;
        padding-top: 20%;

      .frontpage-scroll-video--canvaswrap {
        position: relative;
        transform: translateY(-5%);

        @include aspect-ratio(600, 982);

        canvas,
        img {
          position: absolute;
          z-index: 2;
          @include size(100%, 100%);
          top: 0;
          left: 0;
        }

        img {
          object-fit: cover;
        }
      }


    }


  }

}


.frontpage-img-section-1 {
  position: relative;
  min-height: 50vh !important;

  &:before {
    content: "";
    position: absolute;
    @include size(100%, 100%);
    top: 0;
    background-color: $black;
    left: 0;
    opacity: .4;
  }

  > div {
    position: relative;
    z-index: 2;
  }
}

.frontpage-square-img-usp,
.text-image-cell {

  div[data-area-id] {
    display: flex;
    flex-direction: column;

    .opc-Container {
      height: 100%;

      > div {
        color: $body-color !important;
        min-height: 0 !important;
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: $spacer3;
        padding-right: $spacer3;


      }
    }

  }
}

.text-image-cell {

  div[data-area-id] {
    .opc-Container {
      > div {
        justify-content: center;
      }
    }

  }
}

.frontpage-knownby-logos {
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > div {
      padding: $grid-gutter-width/2;
    }
  }

  picture img {
    max-width: 8rem;
    filter: grayscale(100%);
    opacity: .7;
  }
}

.knm-products-slider {
  @include media-breakpoint-up(xl) {
    transform: translateX(25px);
  }
}


