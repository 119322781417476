body[data-page="11"] {

  #content {
    padding-top: $spacer5;
    padding-bottom: $spacer5;
  }

  #checkout {
    background-color: $white;
    padding: $grid-gutter-width/2;
  }

  .secure-checkout-icon {
    margin-left: auto !important;
    color: $success;
  }

  .secure-checkout-topbar {
    display: none !important;
  }

  .checkout-steps {
    margin-bottom: $spacer5;
  }

}

body[data-page="3"] {

  .cart-items-body {
    strong {
      font-weight: $font-weight-normal !important;
    }


    .shortdescription,
    .characteristic {
      display: none;
    }
  }

  .cart-summary {
    .lpa-button-wrapper {
      padding-top: $spacer3;
    }
  }

  .wishlist-button-text,
  .cart-items-delete-button {
    font-size: $font-size-sm;
  }

}

body[data-page="11"] {
  .checkout-shipping-form-change {
    display: flex;
    align-items: center;

    .btn {
      margin-left: auto;
    }
  }


}

.dropdown-cart-items {
  td {
    border-width: 0;
  }
}

.checkout-payment-method {
  img.img-sm {
    width: 64px
  }
}

#billing_address-state, #register-shipping_address-state {
  display: none;

  + label[for="state"] {
    display: none;

    .state-optional {
      display: none;
    }
  }

}