.badge-secondary {
  background-color: $secondary;
}

.badge-primary {
  color: $white;
}

.badge-light {
  color: $light;
}

//.badge-outline-secondary {
//  border-color: $secondary;
//  color: $secondary;
//}