$vertical-gallery-breapoint-max: 1520px;

body[data-page="1"] {
  h2, .h2 {
    &:not(.product-title) {

      @include media-breakpoint-down(sm) {
        font-size: $h3-font-size;
      }

    }
  }


  h2 {
    margin-bottom: $spacer10;
  }

  .productdetails-description-custom {
    h2 {
      margin-bottom: $headings-margin-bottom;
      font-size: $h2-font-size;

      @include media-breakpoint-down(sm) {
        font-size: $h3-font-size;
      }
    }
  }


  .info-essential {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .product-info {
      text-align: center;
    }

    .swatches {
      justify-content: center;
    }
  }

  #knmFaq_blocks {
    @extend .container;

    .knmFaq_intro {
      font-size: $h2-font-size;
      text-align: center;
    }

    .card h2 {
      margin-bottom: 0;
    }

  }
}

.stock-information {
  @include media-breakpoint-down(md) {
    .question-on-item {
      width:100%;
      padding-top: map_get($spacers,3);
    }
  }
}

.product-alternatives {
  display: flex;
  list-style: none;
  justify-content: center;
  margin-bottom: $spacer5;
  width: 100%;

  li {
    padding: 0 $spacer3;

    span.btn {
      cursor: default;
    }

    .btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $black;
      position: relative;
      justify-content: center;
    }

    svg {
      width: 3rem;
      height: 3rem;
      margin-bottom: $spacer2;
      transition: $transition-base;

      &.active-svg {
        position: absolute;
        top: $btn-padding-y;
        opacity: 0;
      }
    }

    &.active,
    &:hover {

      svg.active-svg {
        opacity: 1;
      }

      svg:not(.active-svg) {
        opacity: 0;
      }
    }

    .product-alternatives--title {
      //padding: $spacer2;
    }
  }
}

#product-offer {

  .product-thumbnails-wrapper {
    margin-left: 0;
  }

  .product-detail-image-preview-bar {
    width: auto;
    max-height:500px;

    .square-image {
      background-color: $white;
    }

    .slick-arrow {
      color: $body-color;
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .knm_thumb_overlay {
      display: flex;
      justify-content: center;
      align-items: center;

      play-wrap {
        left: auto !important;
        top: auto !important;
        position: relative;
        background-color: transparent;
        border-radius: 50%;

        .fas {
          position: relative !important;

        }

      }
    }

    @media (max-width: $vertical-gallery-breapoint-max - 1px) {

      .preview-wrapper-vertical {

        height: 0;
        overflow: hidden;
      }
    }

    .preview-wrapper-horizontal {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .slick-slide {
        height: auto !important;
      }
    }
  }


  @include media-breakpoint-down(sm) {

    #add-to-cart .btn .btn-basket-check {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;

      span {
        display: inline-block !important;
        padding: 0 $spacer2 !important;
      }
    }

    .slick-dots {
      margin-top: $spacer3;
    }
  }


  @media (min-width: 1520px) {
    .preview-wrapper-horizontal {
      display: none;
    }

    .preview-wrapper-vertical {
      display: flex;
      overflow: auto;
      height: auto;


    }

    #image_wrapper:not(.fullscreen) .product-detail-image-preview-bar {
      position: absolute;
      margin-left: -8rem;
      top: 0;
      //z-index: 3;
    }

    #gallery_preview_wrapper_vertical {
      //height:624px;
    }

    #gallery_preview_vertical {
      flex-direction: column;
      align-items: center;
      display: flex !important;
      width: 6rem;
      flex-grow: 1;


      .slick-arrow {
        transform: rotate(90deg);
      }

      .square {
        margin: $spacer2 0;
        width:100%
      }

      .slick-list {
        width: 100%;
      }

      .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .square {
          width: 100%;
          margin: 0;
        }

        .slick-slide {
          margin: $spacer2 0;
          aspect-ratio: 1/1;
        }

      }
    }

    #image_wrapper.fullscreen {
      .preview-wrapper-vertical {
        display: none !important;
      }

      .preview-wrapper-horizontal {
        display: block !important;
      }
    }

    #image-wrapper:not(.fullscreen) {

      .product-thumbnails {
        flex-direction: column;
      }


    }


  }

  .paypal {
    padding-top: .5rem;
  }

  .lpa-button-tooltip-icon {
    display: none !important;
  }

  .ppc-productDetails-horizontal-container {
    position: relative;
    z-index: 1;
  }

}

#image_wrapper {
  .carousel-thumbnails .slick-slide,
  .product-thumbnails:not(.slick-initialized) div.js-gallery-images {
    border-color: $gray-light;
    border-radius: 0;

  }

  .product-thumbnails {

    .inner {
      padding: $spacer1 !important;
    }
  }
}

.product-gallery {
  @include media-breakpoint-up(lg) {
    min-height: 46rem;
  }
}

.shortdesc {
  font-size: $font-size-lg;
}

.variations {
  $swatchSize: 3rem;

  dt {
    color: $body-color;
    font-weight: $font-weight-bold;
  }

  .swatches {
    border-radius: $btn-border-radius;
    display: flex;


    label {
      text-align: center;
      width: 100%;
      margin: 0 auto;
      flex-grow: 1;
      align-items: center;
      height: $swatchSize;
      color: $body-color;
      justify-content: center;
      min-width: 7rem;
      border: 1px solid $secondary;
      margin-bottom: $spacer2;
      position: relative;


      &.swatches-image {
        display: block;
        padding: 0;
        border-width: 0 !important;

        .square-image,
        .square-image picture,
        .square-image .inner,
        .square-image img,
        {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        img {
          transform: none;
          padding: 0;
          display: block;
          object-fit: cover;
        }

        &[data-original="schwarz"] {
          &:after {
            border-color: $white !important;
            border-width: 1px !important;
            margin: 2px;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
          }
        }

        &:after {
          content: "";
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: block;
          position: absolute;
          border: 1px solid $gray-700;
          border-radius: $border-radius;
        }

        &.active {

          &:after {

            border: 2px solid $gray-700
          }
        }


      }

    }


  }

  .swatches {
    &.active {
      border-width: 2px;
      border-color: $gray-700;
      background-color: $white;
      color: $black;
    }
  }

  //dd[data-name="color"] {
  //  .swatches {
  //    width: auto;
  //
  //    label {
  //      display: inline-block;
  //      border-radius: $btn-border-radius;
  //      overflow: hidden;
  //      text-indent: -99999px;
  //      color: transparent;
  //      width: $swatchSize;
  //      height: $swatchSize;
  //      min-width: $swatchSize;
  //      display: flex;
  //      justify-content: center;
  //      align-items: center;
  //      position: relative;
  //      border: 1px solid $black;
  //
  //
  //      &[data-title="weiß"],
  //      &[data-title="white"] {
  //        background-color: $white !important;
  //      }
  //
  //      &[data-title="schwarz"],
  //      &[data-title="black"] {
  //        background-color: $black !important;
  //        border-color: $black !important;
  //      }
  //    }
  //  }
  //}


}

.basket-form-inline {
  .choose_quantity,
  .col:first-child {
    //display: none;
  }

  .choose_quantity {
    .btn {
      @include button-variant($black, $black);
    }
  }

  .btn-primary {
    @include button-variant($black, $black);

  }
}

.additional-attributes {
  .attributes-col-row {
    display: flex;
    margin: $spacer3 -$spacer3;

    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
      padding: $spacer3;

      .collapse {
        max-height: none;
        display: block;
      }
    }


    > div {
      @include make-col(12);
      padding: 0 $spacer3;

      @include media-breakpoint-up(lg) {
        @include make-col(4);
        padding: $spacer3;

        &:nth-child(3n+2) {
          border-left: 1px solid $border-color;
          border-right: 1px solid $border-color;
        }
      }

      h3 {
        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
          font-family: $font-family-base;
          text-align: center;
          font-weight: $font-weight-semibold;
          font-size: $font-size-base;
          flex-grow: 1;
        }
      }
    }


    .collapsible {


      > div {
        @include media-breakpoint-down(md) {
          border-top: 1px solid $border-color;
          overflow: hidden;
          padding: $spacer3 0;
        }


      }


    }

    div[data-toggle="collapse"] {
      cursor: pointer;
      margin-bottom: $spacer3;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        border-top: 1px solid $border-color;
        padding: $spacer3;

        &[aria-expanded="true"] {
          background-color: $gray-lighter;
        }
      }

      @include media-breakpoint-up(md) {
        pointer-events: none;
        display: block;

        h3 {
          text-align: center;
        }

        &:after {
          display: none;
        }
      }
    }

  }
}

.productdetails-xselling-custom {

  .h2.hr-sect {
    display: none;
  }


}

.productdetails-description-custom {
  ul {
    list-style-position: inside;
  }
}

#pushed-success {
  display: flex;
  opacity: 1;

  .modal-dialog {

    @include media-breakpoint-up(md) {
      width: 80vw;
      max-width: map_get($grid-breakpoints, xl);
    }


  }

  .modal-content {
    box-shadow: 0 0 1.625rem rgba($black, .8);

    .image {
      max-width: 5rem;
    }
  }

  .modal-body-inner-mw {
    max-width: map_get($grid-breakpoints, lg);
    margin: 0 auto;
  }
}

// bigger price for fancy qty input overlay

body[data-page="1"].fancyqtyinput-fixed-cart-btn #content #add-to-cart-fixed .price_wrapper .h1 {
  font-size: $h3-font-size !important;
}

// z-index fix for fixed cart btn
body[data-page="1"] div[id*="trustbadge-container"] {
  z-index: 939 !important;
}

// OSS Plugin Fix for fancy cart
body[data-page="1"].fancyqtyinput-fixed-cart-btn #add-to-cart-fixed .price_wrapper {
  @include media-breakpoint-down(md) {

    .ws5_oss_articlewrapper {
      display: none !important;
    }
  }
}

@mixin checkbox($color) {
  margin-right: 1rem;
  padding-left: 1.75rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;

    &:focus ~ span {
      border: 2px solid lighten($black, 50%);
    }

    &:focus:checked ~ span {
      border: 2px solid darken($color, 15%);
    }

    &:checked ~ span {
      color: $white;
      background: $color url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat;
      border: 2px solid $color;
    }

  }

  span {
    border-radius: $border-radius;
    position: absolute;
    left: 0;
    top: -2px;
    width: 1rem;
    height: 1rem;
    background-color: lighten($black, 65%);
    border: 2px solid lighten($black, 65%);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.bundle-list {
  label.checkbox {
    @include checkbox($primary);
  }

  .bundle-checkbox {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    transform: scale(1.5) translate(0, -30%);
  }

  .kreuz {
    background: none !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7
    }

  }
}