.smoothscroll-top {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: $zindex-fixed;
  background: $white;
  border: 1px solid $gray-500;
  border-radius: 10rem;
  padding: .5rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 1;
  right: 1rem;
  bottom: 1rem;
  transition: all .5s ease-in-out;
  transition-delay: .2s;
  background: $white;
  color: $gray-600;
  display: flex;
  align-items: center;
  justify-content: center;


  i {
    font-size: 1.2rem !important;

  }
}

.smoothscroll-top:hover {
  //transition:all .2s ease-in-out;
  //transition-delay: 0s;
}

.smoothscroll-top.show {
  visibility: visible;
  cursor: pointer;
  opacity: 1;
  bottom: 20px;
}

.smoothscroll-top i.fa {
  line-height: inherit;
}