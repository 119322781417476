h3,h4,h5,h6,
.h3,.h4,.h5,.h6 {
  font-family: 'Asap',$font-family-base;
  font-weight: $font-weight-bold;
}

.item-slider-price, .productbox-price {
  font-weight: $font-weight-bold !important;

  &.special-price {
    color: $red;
  }
}

.price {
  color: $headings-color
}

#main-wrapper {
  p,ul {
  a {
    //text-decoration: underline;
    font-weight: bold;
  }
}

}

.text-black {
  color: $black !important;
}

.hr-sect,
.opc-Divider {


  &:before,
  &:after {
   display: none;
  }



}

.font-family-base {
  font-family: $font-family-base;
}

.font-family-serif {
  font-family: $font-family-serif;
}

.number-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $black;
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: $spacer3 auto;
  border-radius: 50%;
  line-height: 1;
  font-size: 1.5em;

  * {
    line-height: 1;
    color: $white;
    margin: 0;

    &:not(.fa) {
      font-family: $headings-font-family;

    }
  }

}

hr {
  border-top: 2px solid $border-color;
  height:0;

}


.headings-color {
  color: $headings-color;
}