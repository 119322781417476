#footer {
  background-color: $footer-bg-color;
  color: $white;
  padding-top: $spacer5*2;

  > .container {
    max-width: $container-fluid-xl-width;
  }

  .h3, h3, p, a {
    color: $white;
  }

  a {
    font-weight: $font-weight-normal;
  }

  ul {
    padding: 0 !important;
  }

  .footer-contact {
    max-width: 40rem;
    background-color: lighten($footer-bg-color, 5);
    padding: map_get($spacers, 5);
    margin: $spacer3 auto;
    text-align: center;
    font-size: $h4-font-size;
    border-radius: $border-radius;
  }

  .productlist-filter-headline {
    margin-bottom: $spacer3;
    font-size: $font-size-lg;
    font-family: $headings-font-family !important;
  }


  @include media-breakpoint-up(md) {

    #footer-boxes {

      [class*="col-"] {

        @include media-breakpoint-up(lg) {
          @include make-col(2.4)
        }

        &:nth-child(1) {

          img {
            max-width: 70%;
          }
        }
      }
    }
  }

  .dropdown .collapse {
    background-color: lighten($footer-bg-color, 5);
    overflow: hidden;
    width: 100%;

  }


  .footer-social {
    padding-top: $spacer5;

    li {
      padding: 0 $spacer2;

      .btn {
        padding: $spacer2;
        @extend .number-circle;
        background-color: transparent !important;
        border: 1px solid $light;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          position: relative;
          z-index: 2;
        }


      }


    }

  }

  .footer-payment {
    padding-top: $spacer5;

    li {
      flex-grow: 0;

      img {
        min-width: 5rem;
        max-width: 5rem;
        margin-bottom: $spacer3;
      }
    }

  }

  .footer-metamenu {
    background-color: $light;

    .box {
      margin-bottom: 0;
    }

    .productlist-filter-headline {
      display: none !important;
    }

    .nav {
      justify-content: center;
      display: flex !important;
      flex-direction: row !important;

      li {
        width: auto;

        a {
          color: $body-color;
          padding: $spacer3 $spacer2;
          font-size: $font-size-base;
        }
      }


    }
  }

  .footnote-vat {
    background-color: $light;
    text-align: center;
    color: $gray-500;
    padding-bottom: $spacer2;
    border-top: 1px solid $gray-300;

    a {
      color: $body-color;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 3rem;
    }
  }

  .box-linkgroup {
    @include media-breakpoint-down(md) {


      a {
        color: $body-color;
        margin-bottom: 0;
      }
    }
  }

  .box-normal-hr {
    display: none;
  }
}


#footer-boxes {


  .box-linkgroup {
    a {
      color: $white;
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.sidepanel-left {
  @include media-breakpoint-down(md) {
    margin-top: map-get($spacers, 4);
  }
  @include media-breakpoint-up(lg) {
    padding-right: map-get($spacers, 5);
  }
  @include media-breakpoint-up(xl) {
    padding-right: map-get($spacers, 7);
  }
}
