$navItemsColWidth: 18rem;

@include media-breakpoint-down(md) {
  header {
    .nav-scrollbar {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100%;
    }

    .navbar-nav {
      transition: $transition-base;
      width: 100%;
      height: 100%;
      flex-direction: column;
      overflow: visible;
      align-self: auto;


      .nav-item {
        padding: 0;

        &.comparelist-nav-scrollbar-item {
          margin-top: auto;
        }
      }

      .nav-link,
      .nav-link,
      .nav-item a {
        padding: $nav-link-padding-y $nav-link-padding-x $nav-link-padding-y 0;
        width: 100%;
        color: $white;
        font-family: $headings-font-family;
        font-size: $font-size-lg;
      }

      .nav-item, .nav-link {
        width: 100%;
        display: flex;
      }

      .nav-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }

      .nav-item {
        border-top: 1px solid rgba($white, .1);

        & {
          .subcategory-wrapper .lg-row-lg > .nav-item-lg-m:first-child {
            display: none;
          }
        }

        &.dropdown {
          position: static;
          flex-direction: column;
          align-items: flex-start;
          display: flex;

          > .nav-link, > a {
            padding-right: 0;

            > .product-count {
              display: none;
            }
          }

          .dropdown-toggler {
            //background-color: rgba($black, .1);
          }

          .dropdown-toggle[aria-expanded="true"] {
            .dropdown-toggler i {
              transform: rotate(180deg);
            }
          }

          .dropdown-toggle:after {
            display: none;
          }
        }


      }

      .dropdown-menu {
        position: relative;
        box-shadow: none;
        width: 100%;
        padding: 0;
        margin: 0;
        height: auto;
        top: 0;
        bottom: auto;
        float: none;
        background-color: rgba($black, .2) !important;

        &:not(.show) {
          display: none;
        }
      }

      .dropdown-body {
        margin: 0;
        padding: $spacer3 0;
        border-radius: $border-radius-sm;

      }

      .subcategory-wrapper {
        width: 100%;
        flex-direction: column;
        padding: 0 !important;
        background-color: transparent !important;

        .nav-item {
          padding-left: $spacer3;
          border-top: 0;


          a {
            font-family: $font-family-base;
            font-size: $font-size-base;
          }
        }
      }

      .nav-items {
        width: 100%;
      }


      .nav {
        width: 100%;
        flex-direction: column;
        margin: 0;
      }
    }

    .nav-mobile-header {
      width: 100%;

      .nav-mobile-header-hr {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .nav-mobile-body {
      width: 100%;
      overflow-y: auto;
      padding-left: $spacer3;
      padding-right: $spacer3;
      //overflow-y: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin: 0 !important;
      height: 100%;
      flex-grow: 1;
    }

    .navbar-expand-lg {
      height: inherit;
    }


    .nav-right {
      font-size: $font-size-lg;

    }
  }

  #header-top-bar .dropdown,
  header .dropdown {
    position: static

  }

  .cart-dropdown,
  .account-icon-dropdown .dropdown-menu {
    width: 100vw;
    margin-top: 0 !important;
  }

  .account-icon-dropdown .dropdown-menu {
    //width: 100vw;
    transform: translate3d(0px, 40px, 0px) !important
  }
}

@include media-breakpoint-up(lg) {

  header {


    .dropdown-body {
      overflow-x: hidden;
      background-color: $light;
    }


    .subcategory-wrapper {
      position: static;
      margin: 0 auto;

      &:before {
        display: block;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100vw;
        height: 2rem;
        pointer-events: none;
        box-shadow: inset 0 1rem 0.5625rem -0.75rem rgba($black, .15);
        content: "";

      }
    }


    .nav-items {

      @include media-breakpoint-up(lg) {
        min-width: $navItemsColWidth;
        position: relative;
        //left: -2rem;
        z-index: 0;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        background-color: $white;
        height: 100%;
        padding: $spacer3;

        .nav-item {

          a {
            position: relative;
            transition: $transition-base;
            border-radius: $border-radius-sm;

            &:hover {
              background-color: $light;
            }
          }

          &.dropdown {
            margin-bottom: $spacer3;

            .dropdown-menu {
              padding-left: $spacer3;
              position: relative;

            }
          }
        }
      }


    }

    .megamenu-blogitem {
      height: 100%;


      .megamenu-blogitem-content {
        z-index: 3;
        background-color: $light;
        padding: $spacer4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: calc((103rem / 2) - 22rem);
        height: 100%;

        .h2 {
          white-space: normal;
        }
      }

      .knm-img {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
      }
    }
  }
}

.box-linkgroup {

  padding: $spacer3;

  .nav {
    &.show,
    &.collapsing {
      display: block;
    }
  }


  li {

    .dropdown-toggle::after,
    :not(.navbar-toggler)[data-toggle=collapse]::after {
      display: none;
    }


    a {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .nav-item.link-group-item-level-1 {
    margin-left: $spacer2;
  }

  .nav-item.link-group-item-level-2 {
    margin-left: $spacer2*1.5;
    font-size: .9rem;
  }
}

.nav-cms-pages {
  background-color: $light;

  @include media-breakpoint-up(lg) {
    margin-top: 2rem;
  }

  .dropdown .collapse {
    background-color: transparent;
    overflow: hidden;
    width: 100%;
  }

  .nav-item {
    &:hover {
      > a {
        //background-color: rgba($black, .1);
      }
    }

    &.active {
      background-color: transparent;
      color: $white;

      > span, > span > a,
      > .nav-link {
        color: $white;
        background-color: $primary;
      }
    }
  }


  .nav-link {
    padding: $spacer1 $spacer1;
    padding: $spacer2;

    > span, > .nav-link {
      //padding:$spacer1 $spacer1;
    }
  }

  > ul > li {
    li {
      &:not(:first-child) {
        border-top: 1px solid $gray-300;
      }
    }
  }


}