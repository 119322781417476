.breadcrumb-container {
  //overflow: hidden;
  position: relative;

  max-width: $container-fluid-xl-width !important;

  @include media-breakpoint-up(md) {
    position: static;

  }

  //[data-page="1"] & {
  //  &:before {
  //    position: absolute;
  //    left: -100vw;
  //    right: 0;
  //    width: 200vw;
  //    height: 5rem;
  //    background-color: $light;
  //    content: "";
  //  }
  //}

}

.breadcrumb-wrapper {
  border-width: 0;

  @include media-breakpoint-up(md) {

    .navigation-arrows {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: 50%;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .btn {
        @include button-variant($primary, $primary);
        background-color: $primary !important;
      }
    }
  }
}