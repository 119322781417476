
.#{$prefix}-btn {
  display: inline-block;
  border-radius: $border-radius;
  border: 0;
  padding: .45rem .85rem;
  font-size: .75rem;
  outline: 0;
  transition: all .2s;
  cursor: pointer;
  border: .0625rem solid transparent;
  @include media-breakpoint-up(md) {
    padding: .85rem 1.5rem;
    font-size: .875rem;
  }
}

@each $color, $value in $theme-colors-consent {
  .#{$prefix}-btn-#{$color} {
    @include button-variant-consent($value);
  }
  .#{$prefix}-btn-outline-#{$color} {
    @include button-outline-variant-consent($value);
  }
}

.#{$prefix}-btn-helper {
  margin: -.25rem;
  display: flex;
  flex-wrap: wrap;

  > div {
    padding: 0 .25rem;
    width: 50%;

    > .#{$prefix}-btn {
      margin: .25rem;
      flex-grow: 1;
      font-weight: $font-weight-semibold;
    }
  }

  .#{$prefix}-accept {
    width: 100%;
    margin-bottom: .5rem;
  }

  @include media-breakpoint-down(md) {
    > div {
      width: 100%;

      > .#{$prefix}-btn {
        width: 100%;
        font-size: $font-size-base;
        display: block;
        padding: .85rem
      }
    }
  }
}

.#{$prefix}-btn-block {
  width: 100%;
}

.#{$prefix}-btn-sm {
  padding: .45rem .85rem;
  font-size: .75rem;
  font-weight: $font-weight-semibold;
}

.#{$prefix}-btn-holder {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-items: left;

  & > div:last-child {
    margin: 0 0 0 auto;
  }

  & > div:first-child {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }

  @include media-breakpoint-up(md) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }

  @include media-breakpoint-down(md) {
    & > div:last-child {
      margin: 1rem 0 0;
    }
  }
}
