//.layout-gallery,
//.slick-slider-other {
//  .product-wrapper {
//    .productbox-images {
//
//      img {
//        background-color: $light;
//      }
//    }
//  }
//
//}

.layout-gallery {
  .productbox {
    display: flex;
    flex-direction: column;
  }

  .product-wrapper {
    text-align: left;
  }

  .productbox-inner {
    height: inherit;
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;


    > .row {
      flex-wrap: nowrap;
      height: inherit;
      display: flex;
      flex-direction: column;

      > div {
        flex: 0;

        &:nth-child(2) {
          flex-grow: 1;
          flex-wrap: nowrap;
          height: inherit;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

}

.productbox-images,
.item-slider.productbox-image {
  margin-bottom: $spacer3;
  background-color: $light;
}


.productbox-title a,
.item-slider-desc {
  margin-bottom: $spacer2;
  color: $body-color;
}

.productbox-price,
.item-slider-price {
  //font-family: $font-family-serif !important;
  font-size: $h4-font-size;

}