// extended bootstrap border utilities

.rounded-top-left {			border-top-left-radius: $border-radius !important; }
.rounded-top-right {		border-top-right-radius: $border-radius !important; }
.rounded-bottom-left {		border-bottom-left-radius: $border-radius !important; }
.rounded-bottom-right {		border-bottom-right-radius: $border-radius !important; }
.rounded-top-left-0 {		border-top-left-radius: 0 !important; }
.rounded-top-right-0 {		border-top-right-radius: 0 !important; }
.rounded-bottom-left-0 {	border-bottom-left-radius: 0 !important; }
.rounded-bottom-right-0 {	border-bottom-right-radius: 0 !important; }

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		@if($infix != '') {
			.border#{$infix}-top-0 {		border-top: 0 !important; }
			.border#{$infix}-right-0 {		border-right: 0 !important; }
			.border#{$infix}-bottom-0 {		border-bottom: 0 !important; }
			.border#{$infix}-left-0 {		border-left: 0 !important; }
			.border#{$infix} {				border-style: solid; border-width: rem(1px); border-color: $border-color; }
			.border#{$infix}-top {			border-top-style: solid; border-top-width: rem(1px); border-top-color: $border-color; }
			.border#{$infix}-right {		border-right-style: solid; border-right-width: rem(1px); border-right-color: $border-color; }
			.border#{$infix}-bottom {		border-bottom-style: solid; border-bottom-width: rem(1px); border-bottom-color: $border-color; }
			.border#{$infix}-left {			border-left-style: solid; border-left-width: rem(1px); border-left-color: $border-color; }
		}

		.border#{$infix}-x {			border-left-style: solid; border-left-width: rem(1px); border-left-color: $border-color; border-right-style: solid; border-right-width: rem(1px); border-right-color: $border-color; }
		.border#{$infix}-y {			border-top-style: solid; border-top-width: rem(1px); border-top-color: $border-color; border-bottom-style: solid; border-bottom-width: rem(1px); border-bottom-color: $border-color; }
		.border#{$infix}-w-0 {			border-width: 0 !important; }
		.border#{$infix}-w-1 {			border-width: rem(1px) !important; }
		.border#{$infix}-w-2 {			border-width: rem(2px) !important; }
		.border#{$infix}-w-3 {			border-width: rem(3px) !important; }
		.border#{$infix}-w-4 {			border-width: rem(4px) !important; }
		.border#{$infix}-w-5 {			border-width: rem(5px) !important; }
	}
}
