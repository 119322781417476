@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

}

@mixin loadingGradient() {
  color: transparent !important;
  background-color: $white !important;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right,
          rgba($white, 1) 30%, rgba($gray-light, 1) 50%, rgba($white, 1) 80%);
  background-size: 200% 100%;
  backface-visibility: hidden;
}

@mixin kildwickScroller() {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: rgba($gray-light, 1) rgba($black, 0.05);
  }

  &::-webkit-scrollbar-track {
    background: $white;
    box-shadow: 0 0 8px rgba($black, .3) inset;

  }

  &::-webkit-scrollbar-thumb {
    background-color: $black;
    //border-radius: 6px;
    &:hover {
      background-color: $dark;
    }
  }

}

// functions to urlencode the svg string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function url-encode($string) {
  $map: (
          "%": "%25",
          "<": "%3C",
          ">": "%3E",
          " ": "%20",
          "!": "%21",
          "*": "%2A",
          "'": "%27",
          '"': "%22",
          "(": "%28",
          ")": "%29",
          ";": "%3B",
          ":": "%3A",
          "@": "%40",
          "&": "%26",
          "=": "%3D",
          "+": "%2B",
          "$": "%24",
          ",": "%2C",
          "/": "%2F",
          "?": "%3F",
          "#": "%23",
          "[": "%5B",
          "]": "%5D"
  );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace($new, $search, $replace);
  }
  @return $new;
}

@function inline-svg($string) {
  @return url('data:image/svg+xml;charset=US-ASCII,#{url-encode($string)}');
}

@mixin chekbox-list {
  margin-bottom: 0;
  padding-left: 0;

  li {
    padding: $spacer3;
    padding-left: $spacer4;
    list-style: none;
    position: relative;
    font-size: $font-size-lg;

    &:before {
      content: fa-content($fa-var-check);
      font-family: "Font Awesome 5 Free";
      position: absolute;
      left: 0;
      font-weight: bold;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-200;
    }
  }
}

@mixin blackOverlay() {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .45);
  }
}

@mixin pos-abs() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}