body[data-page="2"] {

  #content {
    @include make-container();
    @extend .container-fluid-xl;

    .is-not-opc {
      .slick-dots {
        margin-top: 0;
      }
    }


  }

  .subcategories-image {
    background-color: #f9f9f9;
  }

  .btn-filter-box {
    padding-left:0;
    padding-right:0;
  }
}




