.jtl-spinner {
  i {
    margin-top: -40px;
    margin-left: -40px;
    position: fixed;
    top: 50%;
    font-size: 80px;
    left: 50%;
    color: $gray-darker;
    z-index: 100000;
  }
}

body > .jtl-spinner {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.modal-body {
  &.js-collapse-filter {
    min-height: 100px;
  }
}
