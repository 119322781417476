/* filter */
.snippets-filter-item  {
  margin-bottom: map-get($spacers, 2);
  margin-right: map-get($spacers, 2);
  .snippets-filter-custom {
    .badge {
      float: right;
    }
  }
}
.snippets-filter-item-all {
  text-decoration: none;
  display: inline-block;
}
.snippets-filter-show-all {
  width: 100%;
  .btn {
    padding: 0;
    margin-left: auto;
    margin-top: map-get($spacers, 1);
  }
}
.snippets-filter-item-icon-left {
   margin-left: map-get($spacers, 2);
 }
.snippets-filter-item-icon-right {
   margin-right: map-get($spacers, 2);
 }
.snippets-filter-mobile-heading {
  margin-top: map-get($spacers, 3);
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  border: 0;
}
.snippets-filter-mobile-sorting {
  .snippets-filter-mobile-sorting-collapse {
    margin-top: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);
    .filter-item {
      padding-top: map-get($spacers, 1);
      padding-bottom: map-get($spacers, 1);
    }
    .dropdown-item {
      background-color: transparent;
      color: $link-color;
    }
  }
  .snippets-filter-mobile-sorting-link {
    text-decoration: none;
    text-align: left;
    > span {
      float: right;
      margin-left: map-get($spacers, 3);
      margin-right: map-get($spacers, 3);
      padding-right: map-get($spacers, 1);
      text-align: right;
      width: 40%;
    }
  }
}
.snippets-filter-mobile-item {
  .snippets-filter-mobile-item-collapse {
    margin-top: map-get($spacers, 2);
    margin-bottom: map-get($spacers, 2);
    padding-top: map-get($spacers, 2);
    padding-bottom: map-get($spacers, 2);
  }
}
.snippets-filter-mobile-buttons {
  justify-content: end;
  align-items: center;
  .btn {
    margin-top: map-get($spacers, 1);
    margin-bottom: map-get($spacers, 1);
  }
}

/* alert */
.alert-wrapper {
  .close {
    margin-left: map-get($spacers, 3);
  }
}

/* author */
.snippets-author {
  .snippets-author-link {
    display: inline-block;
  }
}
.snippets-author-title {
  margin-left: map-get($spacers, 3);
}

/* banner */
.snippets-banner {
  margin-bottom: map-get($spacers, 5);
  .snippets-banner-image {
    text-align: center;
    margin-bottom: map-get($spacers, 3);
    img {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/* maintenance */
.maintenance-main-wrapper {
  padding-top: map-get($spacers, 5);
  .maintenance-main {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .maintenance-main-item {
    margin-bottom: map-get($spacers, 3);
  }
  .maintenance-main-image {
    margin-bottom: map-get($spacers, 3);
    margin-left: auto;
    margin-right: auto;
    width: 25%;
  }
}

/* product-slider */
.slick-slider-other {
    &.is-not-opc {
      margin-bottom: map-get($spacers, 5);
      .carousel {
        margin-bottom: map-get($spacers, 4);
      }
    }
    .slick-slider-other-container {
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(md) {
        padding-left: map-get($spacers, 3);
        padding-right: map-get($spacers, 3);
      }
    }
    .hr-sect {
      margin-bottom: map-get($spacers, 5);
    }
}

/* shipping-calculator */
.shipping-calculator-form {
  margin-bottom: map-get($spacers, 6);
}
.shipping-calculator-main {
  .shipping-calculator-main-heading {
    margin-bottom: map-get($spacers, 4);
  }
}
.shipping-calculator-hr {
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);
}

/* shipping-tax-info */
.vat_info {
  color: $text-muted;
  .shipping {
    display: inline-block;
  }
  abbr {
    display: inline-block;
  }
}

/* stock-status */
.status-icon {
  margin-right: map-get($spacers, 2);
}

/* slider-items */
.product-wrapper {
  &.freegift {
    .custom-radio {
      padding-left: 0;
    }
    .inner {
      padding-left: map-get($spacers, 2);
      padding-right: map-get($spacers, 2);
    }
  }
}

/* wishlist-button */
.wishlist-button {
  margin-right: map-get($spacers, 3);
  padding: 0 map-get($spacers, 3) 0 0;
  display: block;
  @include media-breakpoint-up(lg) {
    display: inline-block;
    border-right: $border-width solid $border-color;
  }
  .wishlist-button-inner {
    display: flex;
    align-items: baseline;
    .wishlist-icon {
      margin-right: map-get($spacers, 2);
    }
    .wishlist-button-text {
      text-decoration: underline;
    }
  }
}

/* uploads */
.upload-scheme-product {
  margin-bottom: map-get($spacers, 4);
}
.upload-scheme-cart-hr-before {
  margin-top: 0;
  margin-bottom: map-get($spacers, 2);
}
.upload-scheme-cart-hr-after {
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);
}
.upload-scheme-cart {
  .upload-scheme-cart-upload {
    margin-bottom: map-get($spacers, 3);
  }
  .upload-scheme-cart-name {
    font-weight: $font-weight-bold;
    margin-bottom: map-get($spacers, 2);
  }
}

/* wishlist */
.snippets-wishlist {
  .subheadline {
    margin-bottom: map-get($spacers, 5);
  }
  .wishlist-search-wrapper {
    margin-top: map-get($spacers, 3);
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
    .wishlist-search-button {
      .wishlist-search-button-text {
        display: none;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
      }
    }
  }
  .wishlist-privacy-count {
    align-items: center;
  }
  .wishlist-url {
    margin-top: map-get($spacers, 3);
  }
  .wishlist-collapse {
    margin-bottom: map-get($spacers, 3);
  }
  .has-item {
    margin-top: map-get($spacers, 6);
  }
  .wishlist-item {
    margin-bottom: map-get($spacers, 8);
    .productbox {
      padding: map-get($spacers, 2);
      @include media-breakpoint-up(xl) {
        padding: 0;
      }
      .caption {
        text-decoration: none;
      }
      .wishlist-item-buttons {
        .btn {
          margin-top: map-get($spacers, 3);
          align-items: center;
        }
      }
      textarea {
        margin-top: map-get($spacers, 3);
        margin-bottom: map-get($spacers, 3);
      }
      .wishlist-pos-delete {
        float: right;
        margin: - map-get($spacers, 2) 0 0 0;
        padding: map-get($spacers, 1);
        font-size: $font-size-lg;
        text-decoration: none;
      }
    }
  }
  .wishlist-all-to-cart {
    .col {
      margin-left: auto;
    }
  }
  .wishlist-actions {
    align-items: center;
    .wishlist-dropdown-name {
      .dropdown-item {
        padding-top: map-get($spacers, 2);
        padding-bottom: map-get($spacers, 2);
      }
    }
  }
  .wishlist-count {
    margin-left: auto;
  }
  .product-characteristics {
    .col {
      @include text-truncate;
    }
  }
}

/* mobile */
.productlist-applied-filter {
  margin-bottom: map-get($spacers, 5);
  &.productlist-applied-filter-top {
    margin-bottom: 0;
  }
}
.productlist-filter-footer {
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
  margin-top: auto;
}
.snippets-suggestion {
  a {
    display: block;
  }
}
