.selection-wizard-wrapper {
  margin-top: map-get($spacers, 7);
  margin-bottom: map-get($spacers, 7);
  .selection-wizard-desc {
    margin-bottom: 0;
  }
  .selection-wizard {
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 5);
  }
  .selection-wizard-question {
    .row {
      text-align: center;
    }
    .selection-wizard-question-item {
      margin-bottom: map-get($spacers, 3);
    }
  }
}
