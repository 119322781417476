.knm_category_with_count {
  @include media-breakpoint-down(md) {
    margin-left: $spacer2;
    margin-right: $spacer2;
  }
}

.blog-overview {
  padding: 0;
  margin-bottom: $spacer10;


  .blog-overview-preview {
    margin-bottom: $spacer10;

    @include media-breakpoint-up(lg) {
      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      grid-gap: $grid-gutter-width;
      row-gap: $grid-gutter-width;
      width: 100%;


      .blog-overview-preview-item {
        &:nth-child(10n + 1),
        &:nth-child(10n + 2),
        &:nth-child(10n + 6),
        &:nth-child(10n + 7) {
          grid-column: span 6;
        }

        &:nth-child(10n + 3),
        &:nth-child(10n + 9) {
          grid-column: span 7;
          grid-row: span 2;


        }


        &:nth-child(10n + 4),
        &:nth-child(10n + 5),
        &:nth-child(10n + 8),
        &:nth-child(10n + 10) {
          grid-column: span 5;
        }

        //&:nth-last-child(1),
        //&:nth-last-child(2),
        //&:nth-last-child(3) {
        //  grid-row: auto;
        //}


      }

      .knm-img {
        width: 100%;
      }

    }


  }

  .blog-overview-preview-item {
    position: relative;
    margin-bottom: 0 !important;

    @include media-breakpoint-down(md) {
      margin-bottom: $spacer3 !important;
    }

    @include media-breakpoint-up(lg) {


      .knm-img {
        margin-bottom: 0 !important;
        background-color: $black;

        img {
          opacity: .75;
        }

        &.img-4-3 {


          &:before {
            padding-top: 84%;
          }

        }
      }
    }


    .newsbox {
      padding: 0;

      &:before {
        content: "";
        z-index: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background-image: linear-gradient(to bottom, rgba($black, 0) 20%, rgba($black, .7));
        pointer-events: none;
      }

    }

    .newsbox-body {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $spacer3;
      width: 100%;
      z-index: 3;
      pointer-events: none;


    }
  }


}

.blog-details {
  padding-top: $spacer5;
  padding-bottom: $spacer5;

  .blog-details-content {


    img {
      max-width: 100% !important;
      height: auto !important;
    }
  }


}

body[data-page="41"] {

  #main-wrapper {

    h2, h3, h4, h5, h6,
    .h2, .h3, .h4, .h5, .h6 {
      margin-top: 2.5rem
    }

    .opc-Container {

      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        font-family: $font-family-base;
        font-weight: $font-weight-bold;
      }
    }


  }


}

.blog-layout-redesign {
  > .container {
    max-width: $container-fluid-xl-width;
  }

  .blog-details {
    display: none;
  }

}

.blogpost-wide-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include blackOverlay;
  @include media-breakpoint-up(lg) {
    min-height: 30rem !important;
  }

  > div {
    @extend .container;

    > div {
      max-width: 30rem;

      img {
        opacity: .8;
      }
    }
  }

  .opc-Button {
    padding-top: $spacer5;
  }
}

.knm-portlet-share-iconstext {


  .knm-portlet-share-iconstext--list {
    background-color: $black;

    .btn {
      @include hover {
        background-color: $primary;
        border-radius: 0;
      }
    }

    svg {
      width: 3rem;
      height: 1.5rem;
      position: relative;
      z-index: 2;
      transition: $transition-base;
    }
  }
}

.knm-portlet-anchor-list {
  ul > li {
    border-top: 2px solid $border-color;

    &:last-child {
      border-bottom: 2px solid $border-color;
    }

    > a {
      font-weight: $font-weight-bold;
      padding: $spacer3 0;
      transition: $transition-base;

      @include hover {
        background-color: $gray-lighter;
      }

      > .opc-Icon {
        transform: rotate(90deg);
        padding: 0 $spacer3;
      }
    }
  }
}

.opc-Accordion-group {
  .opc-Accordion-head {
    border-radius: 0;
    border-bottom-color: $border-color;

    &:after {
      margin-right: $spacer3;
    }

    @include hover {
      background-color: $gray-lighter;
    }
  }
}