.faq-tabs {

  @include media-breakpoint-up(lg) {

    .nav-tabs {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 0;
      margin-bottom: $spacer10;
      margin-left: -$grid-gutter-width/2;
      margin-right: -$grid-gutter-width/2;

      .nav-item:not(.tab-drop) {
        display: flex;
        position: relative;
        width: 20%;
        flex-basis: 20%;
        padding: 0 $grid-gutter-width/2;
        margin-right: 0;
      }

      .nav-link {


        &:not(.dropdown-toggle) {
          background-color: $gray;
          display: flex;
          width: 100%;
          padding: $spacer2;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: $font-size-base;
          color: $white;
          margin-bottom: $spacer2;

          &:after {
            display: none;
          }
        }


        &.active {
          background-color: $black;
                    border-width: 0 !important;

        }
      }
    }


  }

  .tab-navigation .nav-link.dropdown-toggle {
    display: block;
    //height: 1rem;

    &:after {
      position: relative !important;
    }
  }
}