.register-form-account {
  .register-form-account-unreg, .register-form-account-password , .register-form-account-repeat {
    margin-bottom: map-get($spacers, 3);
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

/* customer-login */
.customer-login-buttons {
  margin-bottom: 0;
  .customer-login-buttons-forgot {
    width: 100%;
    display: block;
    text-decoration: underline;
  }
}
