.opc-Accordion {
  border-width: 0 !important;

  .opc-Accordion-group {
    border: 0 !important;
    padding-left: 0;
    padding-right: 0;

    .card-header {
      background-color: transparent;
      padding: 0;
      border: 0;
      margin-bottom: 0 !important;
      font-size: $font-size-base;

    }

    .opc-Accordion-head {
      display: flex;
      width: 100%;
      padding-top: $spacer3;
      padding-bottom: $spacer3;
      border-bottom: 1px solid $body-color !important;
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      font-size: $font-size-lg;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;

    }

    .card-body.opc-area {
      border-bottom: 1px solid $body-color !important;
      padding-left: 0;
      padding-right: 0;
    }

  }


}

.collapseSlightlyOpened:not(.show) {
  height: 7.5rem;
  overflow: hidden;
  display: block !important;
  position: relative;

  &:before {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    content: "";
    background-image: linear-gradient(to bottom, rgba($white, 0) 20%, rgba($white, 1));
    right: 0;
    left: 0;
    pointer-events: none;
  }
}

