
.#{$prefix}-show-more {
  text-align: right;
  display: block;
  margin: 0;
  @include media-breakpoint-up(md) {
    float: right;
  }
  @include media-breakpoint-down(md) {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: .8rem;
    margin: 1rem 0;
  }
}

.#{$prefix}-more-description {
  background: $rysywhite;
  padding: 1rem;
  border-radius: $border-radius;
  margin-top: 1rem;
}

.#{$prefix}-info {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border: .0625rem solid $gray-consent;
  border-radius: $border-radius;
  padding: .5rem 1rem;
}

##{$prefix}-settings-btn {
  position: fixed;
  left: 1rem;
  bottom: 1rem;
  z-index: $zindex-banner;
  background: $white;
  color:$gray-600;
  border-radius: 10rem;
  padding: .5rem;
  width: 2.5rem;
  height: 2.5rem;
  outline: 0;
  transition: all .2s;
  cursor: pointer;
  transition: all .2s;
  border: 1px solid $gray-500;


  @at-root :not(.mini) > & {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(100%);
  }
  .consent-icon {
    margin: 0;
    width: 100%;
    height: 100%;
  }
}
