body[data-page="5"] {
  #content-wrapper {
    @extend .container;
  }

  #sidepanel_left {
    color: $black;
  }

  .contact-form-hr {
    display: none;
  }

  .contact-form {
    .offset-lg-4 {
      margin-left: 0;
    }

    .row > .col.col-lg-8.col-12,
    .row > .col.col-lg-4.col-12 {
      @include make-col(12);

      legend.h3 {
        margin-bottom: $spacer5;
        font-size: $h3-font-size;
        border-bottom: 1px solid $gray-lighter;
      }

    }
  }
}