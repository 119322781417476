img[class*="wp-image"] {
  @extend .img-thumbnail;




  &.alignleft {
    float:left;
    margin-right: $spacer4;
    margin-bottom: $spacer5;
  }
}


.blog-content {
  img[class*="wp-image"],
  iframe {
    margin: $spacer3 auto;
    display: block;
  }

}

.newsbox-image {
 object-fit: cover;
}

.newsbox-headline {
  color: $headings-color;
}