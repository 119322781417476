.knm-testimonial-slider {

  @include hover {
    .slick-arrow {
      opacity: 1;
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex !important;
    transition: all 250ms ease;
    padding-top:1rem;

    > div {
      display: flex !important;
      height: inherit !important;

    }
  }

  .knm-img {
    width: 5rem;
    margin-top: -2.5rem;
    margin-bottom: 1rem;
    border: 3px solid $white;
  }

  .card {
    border-width: 0;
    height: 100% !important;
    display: flex !important;

    @include media-breakpoint-down(lg) {
      margin-bottom: 4rem;
    }
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}